<section class="header">
    <div class="row max">
        <div class="col-md-6 order-1 title" data-aos="fade-right" data-aos-duration="2000">
            <label>
                <span>
                    La red colaborativa<br />
                    de envíos y viajeros <br />frecuentes</span
                >
                <br />
                Conectamos personas que quieren enviar con quiénes pueden transportar sin limitaciones de tamaño. De
                forma rápida, segura y confiable.
            </label>
            <button><a 
                class="text-decoration-none text-white"
                target="_blank"
                href="https://pwa.tenvio.com.ar"><img class="play" src="../../assets/img/right.svg" /> Usar Tenvío </a></button>
        </div>

        <div class="col-md-6 order-md-2" *ngIf="!mobile" data-aos="zoom-in" data-aos-duration="1000">
            <img src="../../assets/img/intro.jpg" alt="telefono" class="tel" />
        </div>

        <div class="col-md-6 order-md-2" *ngIf="mobile">
            <img src="../../assets/img/intro-min.jpg" alt="telefono" class="tel" height="335" width="346" />
        </div>
    </div>
</section>

<section #section1 class="data" style="padding-top: 5px">
    <!-- <label>
        La plataforma que vincula personas que necesitan hacer envíos
        con personas que pueden transportarlo de forma <strong>segura y confiable.</strong>
    </label> -->

    <div class="row row1">
        <div class="col-lg-4" data-aos="zoom-in">
            <img src="../../assets/img/dr3.svg" alt="loguito" />
            <div class="text-container">
                <label>
                    Envía de forma rápida, <br />
                    segura y confiable.
                </label>
                <div class="separador"></div>
                <p>
                    Publicá y enviá lo que necesites, a donde lo necesites. Sin limitaciones de tamaños. Sin costo de
                    publicación, ni cancelación. Vas a poder elegir quien lo entrega entre los viajeros disponibles.
                    Ahorrá tiempo y dinero.
                </p>
            </div>
        </div>
        <div class="col-lg-4" data-aos="zoom-in">
            <img src="../../assets/img/dr2.svg" alt="loguito" class="img-m" />
            <div class="text-container">
                <label>
                    Aprovechá el viaje. <br />
                    Aumentá tus ingresos.
                </label>
                <div class="separador"></div>
                <p>
                    Ser viajero frecuente y tener espacio extra para transportar envíos, <br />
                    es un COMBO GANADOR.<br />
                    Publicá tu destino, compartilo con la comunidad y generá un ingreso sotenible sin ningún esfuerzo
                    extra.
                </p>
            </div>
        </div>
        <div class="col-lg-4" data-aos="zoom-in">
            <img src="../../assets/img/dr1.svg" alt="loguito" style="margin-top: 38px" />
            <div class="text-container" style="padding-right: 15px; padding-left: 15px">
                <label>
                    ¡Conectá! <br />
                    Es simple y rápido.
                </label>
                <div class="separador"></div>
                <p>
                    Conocé quiénes están por viajar, <br />
                    sus destinos y tarifas. Todos los envíos están asegurados. Para nosotros es fundamental que sepas
                    que cuidamos tu envío como si fuera nuestro.
                </p>
            </div>
        </div>
    </div>

    <div #seguridadSection style="padding-top: 5px">
        <div class="row row2">
            <div class="security-img col-lg-5">
                <img src="../../assets/img/huella.svg" alt="huella" class="huella" data-aos="zoom-in" />
            </div>
            <div class="security-content col-lg-7">
                <label>¿Qué medidas tomamos para garantizar tus envíos?</label>
                <table>
                    <tbody>
                        <tr data-aos="fade-left">
                            <th style="display: block"><img class="security-check" alt="security-check" /></th>
                            <th>
                                <p>
                                    Todos los envíos están asegurados en función al valor declarado por las personas que
                                    envían.
                                </p>
                            </th>
                        </tr>
                        <tr data-aos="fade-left">
                            <th style="display: block"><img class="security-check" alt="security-check" /></th>
                            <th>
                                <p>Validamos la identidad de nuestros viajeros.</p>
                            </th>
                        </tr>
                        <tr data-aos="fade-left">
                            <th style="display: block"><img class="security-check" alt="security-check" /></th>
                            <th>
                                <p>
                                    El sistema de reseñas generado por la comunidad visibiliza el éxito y la
                                    satisfacción de los envíos, generando confianza para las selecciones futuras.
                                </p>
                            </th>
                        </tr>
                        <tr data-aos="fade-left">
                            <th style="display: block"><img class="security-check" alt="security-check" /></th>
                            <th>
                                <p>Seguimiento online del envío en todo su trayecto de origen a destino.</p>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<section class="imagen" #section3>
    <div class="shifter-container" data-aos="fade-right" data-aos-duration="1000">
        <div class="shifter">
            <div class="shifter-header">
                <label class="shifter-header-text"> ¿Cuándo usar Tenvío? </label>
                <div class="shifter-header-actions">
                    <img
                        [ngClass]="{ hide: indexSection3 == 0 }"
                        (click)="changeSection3Info(0)"
                        src="../../assets/img/left.svg"
                    />
                    <img
                        [ngClass]="{ hide: indexSection3 == dataSection3Length }"
                        (click)="changeSection3Info(1)"
                        src="../../assets/img/right.svg"
                    />
                </div>
            </div>
            <div class="shifter-content" [@valueUpdated]="section3Info.title">
                <label class="shifter-content-ask">{{ section3Info.title }}</label>
                <p class="shifter-content-answer">{{ section3Info.subTitle }}</p>
            </div>
        </div>
    </div>
</section>

<section #comunidad class="comunidad">
    <div class="title">
        <label>
            Nuestra red colaborativa
            <br /><span>Experiencias y opiniones de usuarios.</span>
        </label>
    </div>

    <div *ngIf="screenWidth >= 960" class="row row-comunidad">
        <div class="col" *ngFor="let item of usersOpinions" data-aos="zoom-in">
            <app-card-detail [data]="item"></app-card-detail>
        </div>
    </div>

    <carousel
        #myCarousel
        (events)="handleCarouselEvents($event)"
        id="carousel"
        style="height: 500px"
        *ngIf="screenWidth <= 960"
        arrows="0"
        arrowsOutside="false"
        dots="true"
        cellWidth="100%"
        autoplay="true"
    >
        <div class="carousel-cell" *ngFor="let item of usersOpinions">
            <app-card-detail [data]="item"></app-card-detail>
        </div>
    </carousel>
</section>

<section #preguntasFrecuentes class="preguntas-frecuentes">
    <div class="title">
        <label>
            Preguntas <br *ngIf="screenWidth <= 960" />
            Frecuentes <br /><span>Todas tus dudas resueltas acá </span>
        </label>
    </div>
    <mat-accordion class="" multi>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />
                        <label>¿Cómo puedo publicar un envío?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                1 - Ingresá a la APP. <br />
                2 - Publicá tu envío detallando el origen y el destino, el tamaño, la fecha máxima de entrega y el valor
                declarado. <br />
                3 – Vas a poder elegir entre nuestros viajeros de confianza y conocer sus tarifas de traslado. <br />
                4 - Una vez que hayas elegido, y coordines con él los detalles de retiro del envío, solo resta esperar a
                que llegue a destino. 😊 <br />
                <br /><br />
                Conectamos personas que necesitan hacer envíos con quiénes pueden transportar sin limitaciones de
                tamaño. De forma rápida, segura y confiable.</label
            >
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />
                        <label>¿Qué requisitos se necesitan para hacer un envío?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                1 - Obviamente que tengas Tenvío en tu celular. <br />
                2 - Que estés registrado en la APP <br />
                3 - Vamos a pedir que valides tu número de teléfono y mail. Es la manera que tenemos de saber que vos,
                sos vos 😉 <br />
                4 – y ¡listo! Ya podés empezar a publicar. <br />
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label>¿Qué cosas puedo enviar?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                ¡Podés enviar lo que quieras! Desde un alfiler hasta un elefante (¡por decirlo así!). <br />
                Publicá las especificaciones de tu envío, es importante que ingreses tamaño y foto para que los viajeros
                puedan cotizar el precio del viaje sin problemas. <br />
                <br />
                *No está permitido enviar armas de fuego, explosivos ni sustancias prohibidas por ley.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Cómo debería estar embalado mi envío?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Idealmente el envío debería: <br />
                1 – Recomendamos embalar tu envío con algún material impermeable, que permita protegerlo de cualquier
                eventualidad. <br />
                2 – Recomendamos que esté rotulado con la siguiente información: <br />
                • Código de envío (figura en la ficha del envío) <br />
                • Domicilio de origen (Nombre, calle, número, localidad) <br />
                • Domicilio de destino (Nombre, calle, número, localidad) <br />
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Cómo defino la fecha de mi envío?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Al momento de publicar, deberás ingresar la fecha máxima en la que tu viajero de confianza puede
                entregar tu envío.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Los envíos se retiran y entregan a domicilio?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Si, los envíos únicamente se retiran y entregan en los domicilios declarados al momento de la
                publicación.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Cómo sé cuánto va a costarme mi envío?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Luego de completar la publicación, vas a encontrar el precio que cobra cada viajero por trasladar tu
                envío a destino. Cada viajero asigna el precio que considera por su traslado.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Cómo puedo pagar mis envíos?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Por ahora, únicamente en efectivo. Lo que sí, al momento de publicar vas a poder elegir si el pago lo
                haces cuando retiran el envío o con posterioridad a la entrega en destino.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Es seguro utilizar Tenvío?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                ¡Sin dudas! Todos los envíos están pre-asegurados. ¿Qué significa esto? Qué al momento de publicar tu
                envío, la APP te permite declarar el valor preciso del bien que estás enviando. En caso que tu envío no
                llegue a destino (por pérdida, rotura, inconvenientes con el viajero asignado, etc.) te estaremos
                reintegrando el valor declarado.
                <br />
                <br />
                Para nosotros es fundamental que sepas que cuidamos tu envío como si fuera nuestro.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Cómo puedo publicar un viaje?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Si soles subirte a la ruta o recorrer la ciudad por trabajo o placer y tenés espacio extra para
                transportar, entonces ¡esta APP es para vos!
                <br />
                <br />
                Convertí tu tiempo en dinero. Publicá tu destino, compartilo con la comunidad y generá un ingreso
                sostenible sin ningún esfuerzo extra.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Qué requisitos se necesitan para hacer un viaje?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                1 - Obviamente que tengas Tenvío en tu celular. <br />
                2 - Que estés registrado como viajero. <br />
                3 - Los datos que vamos a pedirte al momento del registro son: <br />
                • Número de teléfono <br />
                • Mail <br />
                • Foto de DNI frente y dorso <br />
                • Los datos de tu Tarjeta de Crédito a modo de garantía por los envíos que vas a transportar <br />
                4 - Y por último, vamos a necesitar que termines de validar tu identidad respondiendo algunas preguntas.
                Es la manera que tenemos de saber que vos, sos vos 😉. <br />
                5 - ¡Ya podés publicar tu primer viaje y empezar a ganar dinero! <br />
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Es necesario que ingrese los datos de mi Tarjeta de Crédito?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Sí, es necesario. Te pedimos los datos de tu tarjeta de crédito con el fin de garantizar y asegurar los
                envíos que transportas. En caso que el envío que transportas no llegue a destino (por pérdida ó rotura)
                desde Tenvío estaremos reintegrando el valor asegurado en la publicación al dueño del envío.
                <br />
                Para nosotros es fundamental que sepas que respaldamos tu viaje y cuidamos a nuestra comunidad.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label>
                            ¿Qué tengo que hacer si no encuentro a nadie en el domicilio de retiro o de entrega
                            pautado?</label
                        >
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Si, al momento de retirar - entregar un envío no encontrás a nadie en el domicilio, te pedimos que te
                comuniques por teléfono directamente con la persona que gestionó el envío. Su teléfono figura en la
                sección “Mis viajes”.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> Soy viajero: ¿Tengo que pagar alguna comisión por los viajes qué realizo?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                El precio de tu viaje está compuesto por: <br />
                • El importe que definas por tu viaje al momento de publicarlo + Una comisión + Un seguro (esto lo vas a
                ver detallado en cada envío).
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Cómo pago las comisiones y el seguro de mis viajes a Tenvío?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                En la sección “Mis ingresos” vas a encontrar el detalle de la ganancia de cada viaje que realizaste y
                cuál es la comisión y el seguro a pagar a Tenvío. Desde esa misma sección, vas a poder gestionar el pago
                de esos importes (Comisiones y Seguros). Es importante que sepas que si tenés comisiones y seguros por
                viajes que ya realizaste pendientes de pago, Tenvío no va a permitirte publicar nuevos viajes.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Puedo definir el importe que quiero recibir por cada envío que transporto?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Si, al momento de publicar un viaje vas a poder asignar la ganancia esperada para cada una de las
                diferentes opciones de tamaños de envíos.
            </label>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="d-flex">
                        <img
                            class="mat-exp-panel-header-img"
                            src="../../assets/img/plus_icon.svg"
                            (click)="toggle($event)"
                        />

                        <label> ¿Cuál es mi ganancia para transportes de tamaño especial?</label>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="content">
                Los envíos de tamaño especial operan por cotizaciones por lo cual deberás ingresar un monto cuando
                ofrezcas transportar envíos de ese tamaño.
            </label>
        </mat-expansion-panel>
    </mat-accordion>
</section>

<section class="contacto" style="padding-top: 5px">
    <div class="row row2">
        <div class="col-lg-12 text-center pt-2">
            <label> ¿Tenés dudas? </label>
            <br />
            <p>Te dejamos los datos de nuestro equipo de soporte para que puedas charlar con ellos.</p>
            <div class="row icons">
                <div class="col-lg-4" data-aos="zoom-in">
                    <img src="../../assets/img/phone.svg" alt="" />
                    <a href="tel:549236652220">
                        <span class="text-icon">+54 9 236 465-2220</span>
                    </a>
                </div>
                <div class="col-lg-4" data-aos="zoom-in">
                    <img src="../../assets/img/email.svg" alt="" />
                    <a href="mailto:soporte@tenvio.com.ar">
                        <span class="text-icon">soporte@tenvio.com.ar</span>
                    </a>
                </div>
                <div class="col-lg-4" data-aos="zoom-in">
                    <img src="../../assets/img/whatsapp2.svg" alt="" />
                    <a
                        href="https://api.whatsapp.com/send?phone=5492364330306&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Tenvío%20."
                        target="_blank"
                    >
                        <span class="text-icon">+54 9 236 465-2220</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer"></section>
<!-- <a href="https://wa.me/5492364652220/?text=Envio desde web" class="float" target="_blank">
    <img src="../assets/img/whatsapp.svg" alt="" class="whatsapp">
    </a> -->

<a
    href="https://api.whatsapp.com/send?phone=549236652220&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Tenvío%20."
    class="float"
    target="_blank"
>
    <i class="fa fa-whatsapp my-float"></i>
</a>
